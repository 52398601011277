import React from 'react'
import './App.css'
import MainMenu from './MainMenu'


function App() {
  return (
      <MainMenu/>
  );
}

export default App;
