import React from 'react'
import Tabs from './Tabs'

export default class MainMenu extends React.Component{

  render() {
    return (
      <div>
        <div class='App-header'>
          <h1 className="Header-h1">Baterias Sobrali</h1>
          <p>Distribuidores de baterias TUDOR e outras marcas</p>
        </div>
        <Tabs/>
      </div>
    );
  }
}