import React from 'react'
import { Map, TileLayer, Marker, Popup, LayerGroup } from 'react-leaflet'
import './App.css'

export default class Location extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			lat: 38.910686,
			lng: -9.0274123,
			zoom: 13.5
		}
	}

	render() {
		const position = [this.state.lat, this.state.lng]
		return (
			<div className="App-format">
				<Map center={position} zoom={this.state.zoom}>
					<TileLayer
						attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
						url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
					/>
					<Marker position={position} >
						<Popup>
							Estrada Nacional 10 - km 126.8 <br />  Olival Cruz das Almas R/c, 2615-701 Alverca do Ribatejo
						</Popup>
					</Marker>
				</Map>
			</div>
		)
	}

}