import React from 'react'
import { Document, Page } from 'react-pdf/dist/entry.webpack'
import Spinner from './Spinner'
import batteriesPDF from './baterias.pdf'

export default class PresentPdf extends React.Component{
    
    render() {
        return(
            <div className="App-pdf">
                <Document
                    file={batteriesPDF}
                    loading={<Spinner/>}
                >
                    <Page width="1200" loading = {<Spinner/>} pageNumber={1}/>
                    <Page width="1200" loading = {<Spinner/>} pageNumber={2}/>
                    <Page width="1200" loading = {<Spinner/>} pageNumber={3}/>
                    <Page width="1200" loading = {<Spinner/>} pageNumber={4}/>
                </Document>
            </div>         
        )
    }

}