import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Location from './Location'
import PresentPdf from './PresentPdf'
import Loja from './loja.jpg'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  }
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

export default function CenteredTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: "#8C0005"
          }
        }}
        centered
        className='App-color'
      >
        <Tab label="O Futuro" />
        <Tab label="Baterias" />
        <Tab label= "A nossa Loja" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <div className="App-format">
          <h1 className="App-color">TUDOR</h1>
          <h3>Prepare-se Para O Futuro</h3>
          <p>
            <i>"A Tudor está a lançar a próxima geração de baterias para carros com ganhos significativos a nível de efciência e desempenho. Muitas das ideias que vão encontrar na nova gama, foram inicialmente desenvolvidas para o equipamento original, onde nós, projectamos e produzimos baterias para os principais fabricantes de veículos automóveis.</i><br/>
          </p>
          <p>
            <i>A nova e pioneira gama de baterias Start-Stop, Tudor High Tech com a sua tecnologia de Reforço de Carbono única e componentes tecnológicamente avançados como a comprovada armadura 3DX, que foram projectadas para equipar os veículos modernos com as mais avançadas funções de economia de combustivel.</i><br/>
          </p>
          <p>
            <i>Isto significa um potente desempenho hoje e grande disponibilidade para o futuro."</i>
          </p>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
          <PresentPdf/>
      </TabPanel>
      <TabPanel className='App-center' value={value} index={2}>
        <p>
          <b className="App-color">Telefone / Telemóvel:</b> 21 958 08 77 / 93 623 40 22 <br/>
          <b className="App-color">E-mail:</b> bateriassobrali@gmail.com <br/>
          <b className="App-color">Morada:</b> Estrada Nacional 10 - km 126.8 <br />  Olival Cruz das Almas R/c, 2615-701 Alverca do Ribatejo
        </p>
        
        <img class='Store-images' src={Loja}/>
        <hr/>
        <Location/>
      </TabPanel>
    </Paper>
  );
}
