import React from 'react'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

export default class Spinner extends React.Component{

    render(){
        return(
            <Loader
                type="Puff"
                color="#111111"
                height={50}
                width={50}
            />
        )
    }
}